import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BlogList from './blog_list';
import BlogDetail from './blog_detail';

class Blog extends Component {
  componentDidMount(){
  }
  render() {
    //console.log(this.context);
    var params = this.context.router.route.match.params;
    var view;
    if(params.action===undefined){
      view = <BlogList/>;
    }else{
      if(params.id===undefined){
        view = <BlogDetail/>;
      }else{
        view = <BlogDetail action={params.action}/>;
      }
    }
    return (
      <div className="page-wrapper">{view}</div>
    );
  }
}

Blog.contextTypes = {
  router: PropTypes.object
};

export default Blog;
