import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Typography,Fab,Toolbar,TextField,Select,FormControl} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';

import classNames from 'classnames';
import ReactTable from './shares/react_table';
import {styles} from '../layout/theme';
import Utils from '../api/api';

export class BlogList extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: '',
      searchClick: false,
      filter: '',
      status: '',
      category: '',
      categories: [],
      statuses: ['Draft','Publish','UnPublish'],
    }
  }
  componentDidMount() {
    const $this = this;
    Utils.getListData('blog_category','all',100,'0,0',null,1,function(data){
      if (data.status === 'success') {
        $this.setState({categories: data.results});
      }
    });
  }
  handleSearch(e){
    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }
  handleAdd(){
    this.context.router.history.push('/blog/add');
  }
  filterData(e){
    const $this = this,
          state = $this.state;

    state[e.target.id] = e.target.value.trim();
    $this.setState(state,function(){
      $this.handleFilter();
    });
  }
  handleFilter() {
    let f = this.state.filter;

    if(f==='') {
      f = {};
    } else {
      f = JSON.parse(f);
    }

    if(this.state.category!==''){
      f.category = parseInt(this.state.category);
    } else {
      if(f.category!==undefined) {
        delete f.category;
      }
    }

    if(this.state.status!==''){
      f.status = parseInt(this.state.status);
    } else {
      if(f.status!==undefined) {
        delete f.status;
      }
    }

    this.setState({filter:JSON.stringify(f)});
  }
  render(){
    const $this = this;
    const classes = $this.props.classes;
    var source = {
      thead: [{title:'Tiêu đề',value:'title'},{title:'Nhóm',value:'category'}, { title: 'Ngày đăng', value: 'date'},{title:'Trạng thái',value:'status'}],
      tbody: function (n) {
        return ({
          _id: n._id,
          title: n.titleVN,
          category: $this.state.categories.length&&$this.state.categories.map(x=>{
            return x._id==n.category?x.nameVN:'';
          }),
          date: Utils.formatDate(n.date),
          status: $this.state.statuses[n.status]
        })
      }
    };
    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography variant="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục bài viết blog</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
            <FormGroup className={classes.formRowToolbar}>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col4}>
                  <Typography className={classes.label}>Nhóm</Typography>
                  <Select
                    native
                    id="category"
                    value={$this.state.category}
                    onChange={event => this.filterData(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.categories.map((item, index) => {
                      return (
                        <option key={index + 1} value={item._id}>
                          {item.nameVN}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.col4}>
                  <Typography className={classes.label}>Trạng thái</Typography>
                  <Select
                    native
                    id="status"
                    value={$this.state.status}
                    onChange={event => this.filterData(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.statuses.map((item, index) => {
                      return (
                        <option key={index + 1} value={index}>
                          {item}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.col4}>
                  <TextField type="search" id="keyword" label="Tìm kiếm" className={classNames(classes.textField + ' form-control-no-bottom')} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value.trim()})}
                  margin="normal"/>
                </FormControl>
              </FormGroup>
            </FormGroup>
            
            <Fab color="primary" aria-label="search" className={classes.button} onClick={(e)=>this.handleSearch(e)}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiDuplicate={true} apiTable='blogs' router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick} apiFilter={this.state.filter}>
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

BlogList.propTypes = {
  classes: PropTypes.object.isRequired,
};
BlogList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(BlogList);
